import React from "react";
import FooterSection from "../components/FooterSection";
import MilkHeader from "../components/MilkHeader";
import PageMeta from "../components/PageMeta";
import KYCLanding from "../components/KYCLanding";

const KYCPage = () => {
  return (
    <>
      <main>
        <PageMeta title="KYC" description="KYC page" locale="en_US" language="en" />
        <MilkHeader />
        <KYCLanding />
      </main>
      <FooterSection footerImage={"/images/footer-bg@2x.webp 2x, /images/footer-bg.webp 1x"} />
    </>
  );
};

export default KYCPage;
