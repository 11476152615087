import React from "react";

const KYCLandingFormLazyLoaded = React.lazy(() => import("./KYCLandingForm"));

// Lazy loading the KYCLandingForm component because it uses a client-side package
// we don't want to do SSR here
const KYCLandingFormLazyLoader = () => {
  const isSSR = typeof window === "undefined";

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <KYCLandingFormLazyLoaded />
        </React.Suspense>
      )}
    </>
  );
};

export default KYCLandingFormLazyLoader;
