import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";

import KYCLandingFormLazyLoader from "./KYCLandingLoader";

const KYCLanding = () => {
  return (
    <div>
      <section className="w-full relative z-20 -mb-10">
        <div className="absolute w-full h-full top-0 left-0 -z-10">
          <img
            className="w-full h-full object-cover"
            srcSet="/images/page-bg@2x.webp 2x, /images/page-bg.webp 1x"
            src="/images/page-bg.webp"
            width="1600"
            height="502"
            alt="page-bg"
          />
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className="container pt-110 lg:pt-185 sc-up-mb relative">
          <div className="text-center">
            <h1 className="heading-1 heading-1-2">
              <Trans>KYC/AML</Trans>
            </h1>
          </div>
          <div className="space-custom"></div>
        </div>

        <div className="w-full -z-10">
          <img
            className="w-full"
            srcSet="/images/oval_BG@2x.webp 2x, /images/oval_BG.webp 1x"
            src="/images/oval_BG.webp"
            width="1600"
            height="235"
            alt="oval-top"
          />
        </div>
      </section>
      <section className="relative">
        <div className="bg-blue-bg-dark">
          <div className="container relative z-20 pt-[40px] lg:pt-[80px] pb-[40px] lg:pb-[80px]">
            <div className="md:max-w-[500px] md:mx-auto" id="veriff-holder">
              <KYCLandingFormLazyLoader />
            </div>
          </div>
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default KYCLanding;
